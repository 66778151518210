import React from "react";
// import { ButtonContent } from "../global/boton/ButtonContent";
import { GlobalDataContext } from "../../context/context";

const ServicesBlock = ({ ServiceName, TextService, bgimg, ClassesRow, DisplayNone, itemServices, classesMargin }) => {

  const { rpdata } = React.useContext(GlobalDataContext);

  return (
    <div
      className="md:flex  md:flex-row content-text-bg md:p-0 px-2 my-[200px] "
      style={{
        flexDirection: `${ClassesRow}`
      }}
    >
      <div className={`md:w-[50%] md:self-center md:my-[-40px] ${classesMargin} md:p-10 p-4  shadow-lg bg-footer z-10 rounded-xl`}>
        <h3 className={DisplayNone}>
          {ServiceName}
        </h3>
        {/* <div className={`md:w-[50%] w-full h-0.5 bg-black my-2 ${DisplayNone}`}></div> */}
        <p>{TextService}</p>
        <ul>
          {itemServices}
        </ul>
        {/* <ButtonContent btnStyle='three' /> */}

        <button class="relative group cursor-pointer text-sky-50  overflow-hidden h-16 w-64 rounded-md bg-sky-800 p-2 mt-4 flex justify-center items-center font-extrabold">

          <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
          <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
          <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
          <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
          <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} class="z-10">Contact us</a>
        </button>


      </div>
      <div className="md:w-[50%] w-full flex self-center">
        <div
          className="w-full md:h-[500px] h-[350px] bg-cover bg-center"
          style={{ backgroundImage: `url("${bgimg}")` }}
        ></div>
      </div>
    </div>
  );
};

export default ServicesBlock;